<template>
  <div class="basic-info">
    <div class="header">
      <div class="header_top">
        <img src="../../assets/left.png"
             alt
             @click="goBack" />
        <span class="center">合同管理</span>
        <img src="../../assets/right.png"
             alt />
        <span class="center">合同列表</span>
        <img src="../../assets/right.png"
             alt />
        <span class="center">合同详情</span>
      </div>
    </div>
    <el-card>
      <el-card class="box-card">
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>合同基本信息</span>
          </div>
        </div>
        <div class="text item">
          <el-row>
            <el-col :span="12"
                    class="text-align">名称：{{ detailInfo.name }}</el-col>

            <el-col :span="12"
                    class="text-align">
              签署状态：
              <span v-if="detailInfo.signFlowStatus==='2'">签署完成</span>
              <span v-if="detailInfo.signFlowStatus==='100'">待发起</span>
              <span v-if="detailInfo.signFlowStatus==='101'">签署中</span>
              <span v-if="detailInfo.signFlowStatus==='103'">已完成</span>
              <span v-if="detailInfo.signFlowStatus==='104'">审核不通过</span>
              <span v-if="detailInfo.signFlowStatus==='105'">待企业签署</span>
              <span v-if="detailInfo.signFlowStatus==='3'">失败</span>
              <span v-if="detailInfo.signFlowStatus==='5'">已过期</span>
              <span v-if="detailInfo.signFlowStatus==='7'">拒签</span>
            </el-col>
            <el-col :span="12"
                    class="text-align">
              合同分类：
              <span v-if=" detailInfo.type==='1'">入职合同</span>
              <span v-if=" detailInfo.type==='2'">离职合同</span>
              <span v-if=" detailInfo.type==='3'">用工合同</span>
            </el-col>
            <el-col :span="12"
                    class="text-align"
                    v-for="(item, index) in detailInfo.signers"
                    :key="index">
              {{ index |
              subjectTypeVal
              }}：{{ item.subjectName || "未设置" }}
            </el-col>
            <el-col :span="12"
                    class="text-align"
                    v-for="(item, index) in detailInfo.signers"
                    :key="index + 'i'">
              {{ index | subjectTypeVal }}签署人：{{
                    item.signerName || "未设置"
                  }}

              <i class="iconfont"
                 :class="{
                iconclock: item.signResult === '3'|| item.signResult === '101',
                iconsuccess: item.signResult === '2',
                iconreeor: item.signResult === '4',
              }"></i>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <el-card class="box-card">
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>合同附件</span>
          </div>
        </div>
        <div class="card-body">
          <div v-for="(item, i) in fileList"
               :key="i">
            <span>附件{{ i + 1 }}：</span>
            <p style="display: inline-block; min-width: 350px">{{ item.name }}</p>
            <a style="color: #409eff"
               :href="item.path"
               target="_blank">预览</a>
          </div>
          <div style="text-align: center">
            <commonEmpty :emptyMessage="'暂无合同附件'"
                         v-if="fileList.length === 0"></commonEmpty>
          </div>
        </div>
      </el-card>
    </el-card>
  </div>
</template>
<script >
import { api } from '/src/api/base/index';
const contractDescribe = api('/contract')('contract.info.json');
const ContractDel = api('/contract')('contract.del.json');
export const ContractSignUrlDescribe = api('/contract')(
  'contract.signer.url.json'
);

import commonEmpty from '/src/components/common-empty/common-empty';
export default {
  components: {
    commonEmpty
  },
  data () {
    return {
      code: '',
      detailInfo: {
        code: '',
        name: '',
        signDeadline: '',
        flowId: '',
        state: '',
        type: '',
        signerAccounts: '',
        signerNames: '',
        subjectAccounts: '',
        subjectNames: '',
        subjectTypes: '',
        filePaths: '',
        esignFileIds: '',
        creatorAccount: '',
        creatorName: '',
        config: ''
      },
      singerList: [],
      fileList: []
    };
  },

  async mounted () {
    this.code = this.$route.query.code;
    await this.getInfo();
  },
  methods: {
    async getInfo () {
      await contractDescribe({ code: this.code }).then(res => {
        let a = []
        let b = []
        if (new Set(res.signer.map(m => m.psnId)).size === 2) {
          a = res.signer.filter(i => i.orgId != '')
          b = res.signer.filter(i => i.orgId === '')

        }
        this.detailInfo = {
          ...res,
          signers: [{ subjectName: a.length ? a[0].orgName : '', signerName: a.length ? a[0].psnName : '', signResult: a.length ? a[0].signResult : '', }, { subjectName: b.length ? b[0].psnName : '', signerName: a.length ? b[0].psnName : '', signResult: a.length ? b[0].signResult : '' }],

        }
        if (this.detailInfo.filePaths) {
          this.detailInfo.filePaths.split(',').forEach(it => {
            this.fileList.push({
              name: it.split('/')[it.split('/').length - 1],
              path: it
            });
          });
        }
      });

    },
    goBack () {
      window.history.go(-1);
    },
    async delContract () {
      await ContractDel({
        code: this.code
      });
      this.$message.success('内容成功删除！');
      window.history.back(-1);
    },
    async getSignUrl () {
      const url = await ContractSignUrlDescribe({
        contractCode: this.code,
        signerAccount: window.localStorage.getItem('code')
      });
      window.location.href = url;
    }
  }
};
</script>

<style lang="scss">
.basic-info .last-breadcrumb {
  color: rgba(0, 0, 0, 0.8);
}

.basic-info .font-img {
  font-size: 26px;
  cursor: pointer;
}

.showImgDialog .imgBox {
  display: flex;
}

.img-resource {
  width: 300px;
  height: 250px;
  margin: 0 auto;
}

.card-header {
  display: flex;
  font-size: 14px;
  align-items: center;
}

.card-icon {
  display: flex;
  width: 4px;
  height: 15px;
  background: #659fe5;
  margin-top: 3px;
  margin-right: 10px;
}

.card-title {
  margin-right: 8px;
}

.card-btn {
  cursor: pointer;
}

.text {
  font-size: 14px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.text-align {
  text-align: left;
  line-height: 32px;
  padding-bottom: 8px;
}

.line-inherit {
  line-height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.marginBottom {
  margin-bottom: 12px;
}

.statusPointer {
  cursor: pointer;
  margin-right: 12px;
  font-size: 14px;
}

.aType {
  color: #659fe5;
  cursor: pointer;
}

.tooltipClas {
  width: 250px;
}

.basic-info {
  .warning {
    color: #e6a23c;
  }

  .success {
    color: #67c23a;
  }

  .danger {
    color: #f56c6c;
  }

  .info {
    color: #409eff;
  }
}

.defaultTips {
  display: inline-block;
  margin: 0 8px;

  span {
    display: inline-block;
    font-size: 12px;
    color: red;
    line-height: 32px;
    text-align: center;
  }
}

.card-body {
  text-align: left;
  line-height: 32px;
  padding-bottom: 8px;
  font-size: 14px;
  color: #303133;
}

.iconsuccess {
  color: #67c23a;
}

.iconreeor {
  color: #f56c6c;
}
</style>
